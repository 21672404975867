const YourAppDotIeFooter = () => {

  const currentYear = (new Date()).getFullYear();

  return (
    <div style={{
      width: '100%',
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: '10px',
    }}>
      <div>
        ©2022 - {currentYear} Site designed, developed and maintained by <a href="http://yourapp.ie">YourApp.ie</a>&nbsp;
      </div>
    </div>
  );
}

export default YourAppDotIeFooter;